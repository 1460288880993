<template>
  <v-dialog v-model="open"
            content-class="commission-v-dialog"
            v-if="commissionValue"
            width="600px"
            @click:outside="$emit('close')"
            @keydown.esc="$emit('close')">
    <v-card class="commission-edit-confirm-modal">
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <p>на {{ commissionValue }}</p>
        <p>{{ text }}</p>
      </v-card-text>
      <v-card-actions class="commission-edit-confirm-modal__actions">
        <v-btn text class="commission-edit-confirm-modal__actions--cancel" @click="$emit('close')">
          ОТМЕНИТЬ
        </v-btn>
        <v-btn text class="commission-edit-confirm-modal__actions--save"  @click="$emit('confirm')">
          ПОДТВЕРДИТЬ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'CommissionEditConfirm',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    commissionValue: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
.commission-v-dialog {
  &.v-dialog {
    border-radius: 0;
  }
}
.commission-edit-confirm-modal {
  border-radius: 0!important;
  &__actions {
    &.v-card__actions {
      padding: 12px 20px!important;
    }

    .v-btn__content {
      color: #007450;
    }
    .v-btn {
      border-radius: 18px;
    }
  }
}
</style>
