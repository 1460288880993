<template>
  <v-container fluid class="pa-7 crm-page-height">
    <div class="commission-items">
      <div class="commission-items__item" v-for="commission in commissionType" :key="commission.id">
        <RoundedBtn
                    v-if="commission.fullInfo"
                    :append="commission.fullInfo"
                    :prepend="commissionDictionary[commission.code].icon"
                    :text="commissionDictionary[commission.code].label"
                    :disabled="isChief"
                    @handleActions="openEditDialog(commission)"/>
      </div>
<!--      <div class="commission-items__item">-->
<!--        <RoundedBtn-->
<!--            v-if="bonus && bonus.value"-->
<!--            :append="`${bonus.value} %`"-->
<!--            :prepend="commissionDictionary['Bonus'].icon"-->
<!--            :text="commissionDictionary['Bonus'].label"-->
<!--            :disabled="isChief"-->
<!--            @handleActions="openBonusEditDialog(bonus)"/>-->
<!--      </div>-->
    </div>
    <commission-edit :open="openDialog"
                     :commissionType="editCommission"
                     :commissionKind="commissionKind"
                     :currency="currency"
                     :title="editTitle"
                     @save="reloadCommissions"
                     @close="closeEditDialog"/>
    <bonus-edit :open="openBonusDialog"
                :loaded-bonus="editCommission"
                :commission-kind="commissionKind"
                :title="editTitle"
                @save="reloadCommissions"
                @close="closeBonusEditDialog"/>
  </v-container>
</template>
<script>
import RoundedBtn from '@/components/RoundedBtn.vue';
import lodash from 'lodash';
import { mapState } from 'vuex';
import CommissionEdit from './components/CommissionEdit.vue';
import BonusEdit from './components/BonusEdit.vue';

export default {
  components: {
    CommissionEdit,
    RoundedBtn,
    BonusEdit,
  },
  data() {
    return {
      openDialog: false,
      openBonusDialog: false,
      editCommission: null,
      editBonus: null,
      editTitle: null,
      commissions: [],
      commissionKind: [],
      commissionType: [],
      currency: [],
      commissionDictionary: {
        Delivery: {
          label: 'Доставка',
          dialogTitle: 'Тариф доставки на текущую дату',
          icon: 'mdi-truck-fast',
        },
        Platform: {
          label: 'Комиссия платформы',
          dialogTitle: 'Комиссионая ставка платформы на текущую дату',
          icon: 'mdi-label-percent-outline',
        },
        Bonus: {
          label: 'Бонусы',
          dialogTitle: 'Бонусы',
          icon: 'mdi-cash',
        },
      },
      bonus: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    async reloadCommissions() {
      await this.init();
      this.closeEditDialog();
      this.closeBonusEditDialog();
    },
    openEditDialog(commission) {
      this.editCommission = { ...commission };
      this.editTitle = this.commissionDictionary[commission.code].dialogTitle;
      this.openDialog = true;
    },
    openBonusEditDialog(bonus) {
      this.editBonus = { ...bonus, code: 'Bonus' };
      this.editTitle = 'Бонусы';
      this.openBonusDialog = true;
    },
    closeEditDialog() {
      this.openDialog = false;
      this.editCommission = null;
      this.editTitle = null;
    },
    closeBonusEditDialog() {
      this.openBonusDialog = false;
      this.editBonus = null;
      this.editTitle = null;
    },
    getActualCommissions() {
      return this.$billing.get('commission/getLastCommissions');
    },
    getActualBonuses() {
      return this.$billing.get('Bonus/getLastBonus');
    },
    async init() {
      const p1 = this.$dictionary.get('commissionKind');
      const p2 = this.$dictionary.get('commissionType');
      const p3 = this.$dictionary.get('currency');
      const p4 = this.getActualCommissions();
      // const p5 = this.getActualBonuses();
      // await Promise.all([p1, p2, p3, p4, p5]).then(async (values) => {
      //   this.commissionKind = values[0].data;
      //   this.commissionType = values[1].data;
      //   this.currency = values[2].data;
      //   this.commissions = lodash.groupBy(values[3].data, 'type');
      //   await this.calcCommission();
      //   this.bonus = values[4].data;
      // });
      await Promise.all([p1, p2, p3, p4]).then(async (values) => {
        this.commissionKind = values[0].data;
        this.commissionType = values[1].data;
        this.currency = values[2].data;
        this.commissions = lodash.groupBy(values[3].data, 'type');
        await this.calcCommission();
      });
    },
    calcCommission() {
      this.commissionType.forEach((el) => {
        if (this.commissions[el.name]) {
          if (this.commissions[el.name][0].currency) {
            // eslint-disable-next-line no-param-reassign
            el.fullInfo = `${this.commissions[el.name][0].value} ${this.commissions[el.name][0].currency}`;
          } else {
            // eslint-disable-next-line no-param-reassign
            el.fullInfo = `${this.commissions[el.name][0].value} %`;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          el.fullInfo = 'не указано';
        }
      });
    },
  },
};
</script>
<style lang="scss">
.commission-items {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  flex-direction: row;
  width: 100%;
  padding-top: 12px;
  row-gap: 15px;
  align-content: flex-start;

  &__item {
    .rounded-btn-text {
      margin-right: 10px;
    }
  }
}
</style>
