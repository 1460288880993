<template>
  <v-dialog v-model="open"
            width="1200px"
            @click:outside="clearForm()"
            @keydown.esc="clearForm()">
    <v-card class="bonus-edit-modal">
      <v-card-title>{{ title }}</v-card-title>
      <v-divider class="elevation-3"></v-divider>
      <v-card-text>
        <v-row class="my-5">
          <v-col cols="4" >
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="bonus.value"
                label="Тариф"
                type="number"
                :rules="[v=> !!v || 'Укажите тариф']"
                required/>
              <v-select :items="kinds" item-text="name" item-value="id"
                        v-model="bonus.kindId"
                        label="Ед.измерения"
                        :rules="[v=> !!v || 'Укажите ед измерения']"/>
              <v-select
                :items="months" item-text="name" return-object
                v-model="bonus.months"
                label="Срок действия"
                :rules="[v=> !!v || 'Укажите срок']"
              />
            </v-form>
          </v-col>
          <v-col cols="8">
            <crm-table-pageable-new
                :headers="headers"
                :data="bonusHistory"
                :config="config"
                :loaded-options="options"
                :total-elements="totalElements"
                :total-pages="totalPages"
                item-key="id"
                tableWrapperMinHeight="calc(100vh - 500px)"
                @onChangeData="onChangeData"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="bonus-edit-modal__actions">
        <v-btn text class="bonus-edit-modal__actions--cancel" @click="clearForm()">
          Закрыть
        </v-btn>
        <v-btn text class="bonus-edit-modal__actions--save" @click="validate()">
          Установить новый бонус
        </v-btn>
      </v-card-actions>
    </v-card>
    <commission-edit-confirm
        @confirm="saveEdit"
        @close="openConfirm = false"
        :open="openConfirm"
        :title="title"
        text="Подтвердите изменение бонуса"
        :commission-value="fullInfo"
    />
  </v-dialog>
</template>
<script>
import CrmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import CommissionEditConfirm from './CommissionEditConfirm.vue';

export default {
  components: {
    CommissionEditConfirm,
    CrmTablePageableNew,
  },
  name: 'CommissionEdit',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Редактирование',
    },
    loadedBonus: {
      type: Object,
      default: () => {},
    },
    commissionKind: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      openConfirm: false,
      bonus: {
        value: null,
        kindId: null,
        months: null,
      },
      headers: [
        {
          value: 'createdDate', text: 'Дата создания', type: 'defaultItem', sortable: false,
        },
        {
          value: 'value', text: 'Тариф', type: 'defaultItem', sortable: false,
        },
        {
          value: 'measure', text: 'Ед.измерения', type: 'defaultItem', sortable: false,
        },
        {
          value: 'expireDate', text: 'Срок действия', type: 'defaultItem', sortable: false,
        },
        {
          value: 'author', text: 'Автор', type: 'defaultItem', sortable: false,
        },
      ],
      bonusHistory: [],
      options: {
        page: 1,
        size: 10,
      },
      config: {
        search: true,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      months: [
        {
          value: 1,
          name: '1 месяц',
        },
        {
          value: 3,
          name: '3 месяц',
        },
        {
          value: 6,
          name: '6 месяц',
        },
        {
          value: 9,
          name: '9 месяц',
        },
        {
          value: 12,
          name: '1 год',
        },
      ],
    };
  },
  watch: {
    open: {
      handler(val) {
        if (val) this.onChangeData(this.options);
      },
    },
  },
  computed: {
    fullInfo() {
      if (this.bonus.value) {
        return `${this.bonus.value}% на ${this.bonus.months.name}`;
      }
      return '';
    },
    kinds() {
      return this.commissionKind.filter((i) => i.id === 1);
    },
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.options = { ...resultOptions };
      this.getBonusHistory();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.openConfirm = true;
      } else {
        setTimeout(() => {
          this.$refs.form.resetValidation();
        }, 5000);
      }
    },
    saveEdit() {
      const { value, kindId, months } = this.bonus;
      this.$billing.post('bonus', {
        months: months.value,
        kindId,
        value: +value,
      }).then(() => {
        this.openConfirm = false;
        this.$refs.form.reset();
        this.$emit('save');
      });
    },
    getBonusHistory() {
      this.$billing.get('bonus', {
        params: {
          PageNumber: this.options.page,
          PageSize: this.options.size,
          Sorting: this.options.sort,
          'Search.Value': this.options.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / (this.options.size || 1));
        this.bonusHistory = response.data.map((item) => ({
          ...item,
          createdDate: this.$moment(item.created).format('DD-MM-YYYY HH:mm'),
          measure: item.currency ? item.currency : '%',
        }));
      });
    },
    clearForm() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.bonus-edit-modal {
  &__actions {
    &.v-card__actions {
      padding: 12px 34px 20px!important;
    }

    &--save {
      background-color: #007450;
      border-radius: 18px;

      .v-btn__content {
        color: #FFFFFF;
        padding: 7px 18px;
      }
    }

    &--cancel {
      color: #007450;
      border-radius: 18px;
    }
  }
}
</style>
