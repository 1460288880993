<template>
  <v-btn rounded
         large
         outlined
         :value="value"
         class="rounded-btn py-6 px-3 d-flex elevation-2"
         color="transparent"
         @click="showDialog(value)"
         :disabled="disabled"
  >
    <v-icon left color="#007450" class="ml-3" style="font-size: 21px">{{prepend}}</v-icon>
    <span class="rounded-btn-text">{{text}}</span>
    <v-chip
      color="#39B54A"
      text-color="white"
      class="ml-auto"
    >
      {{append}}
    </v-chip>

  </v-btn>
</template>
<script>
export default {
  props: {
    value: {
      default: null,
    },
    prepend: {
      default: '',
    },
    append: {
      default: '',
    },
    text: {
      default: '',
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    showDialog(item) {
      this.$emit('handleActions', {
        item,
      });
    },
  },
};
</script>
<style scoped>
.rounded-btn {
  width: auto;
  min-width: 310px;
  border: 1px solid #007450;
}
span.rounded-btn-text {
  max-width: 190px;
  color: #007450;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  line-height: 1;
  float: left;
}
</style>
