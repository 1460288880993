<template>
  <v-dialog v-model="open"
            v-if="commissionType"
            width="1200px"
            @click:outside="clearForm()"
            @keydown.esc="clearForm()">
    <v-card class="commission-edit-modal">
      <v-card-title>{{title}}</v-card-title>
      <v-divider class="elevation-3"></v-divider>
      <v-card-text>
        <v-row class="my-5">
          <v-col cols="4" >
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="commission.value"
                label="Тариф"
                type="number"
                :rules="[v=> !!v || 'Укажите тариф']"
                required/>
              <v-select :items="commissionKind" item-text="name" item-value="id"
                        v-model="commission.commissionKind"
                        label="Ед.измерения" @change="clear()"
                        :rules="[v=> !!v || 'Укажите ед измерения']"/>
              <v-select
                v-if="commission.commissionKind === 2"
                :items="currency" item-text="nameRu" return-object
                v-model="commission.currency"
                label="Валюта"
                :rules="[v=> !!v || 'Укажите валюту']"
              />
            </v-form>

          </v-col>
          <v-col cols="8">
            <crm-table :headers="headers" :data="commissionHistory"></crm-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="commission-edit-modal__actions">
        <v-btn text class="commission-edit-modal__actions--cancel" @click="clearForm()">
          Закрыть
        </v-btn>
        <v-btn text class="commission-edit-modal__actions--save" @click="validate()">
          Установить новый тариф
        </v-btn>
      </v-card-actions>
    </v-card>
    <commission-edit-confirm
        @confirm="saveEdit"
        @close="openConfirm = false"
        :open="openConfirm"
        :title="title"
        :text="commissionDictionary[commissionType.code].text"
        :commission-value="fullInfo"
    />
  </v-dialog>
</template>
<script>
import crmTable from '@/components/crm-table/index.vue';
import CommissionEditConfirm from './CommissionEditConfirm.vue';

export default {
  components: {
    CommissionEditConfirm,
    crmTable,
  },
  name: 'CommissionEdit',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Редактирование',
    },
    commissionType: {
      type: Object,
      default: () => {},
    },
    commissionKind: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      openConfirm: false,
      commission: {
        value: null,
        commissionKind: null,
        currency: null,
      },
      headers: [
        {
          value: 'createdDate', text: 'Дата создания', type: 'defaultItem', sortable: false,
        },
        {
          value: 'value', text: 'Тариф', type: 'defaultItem', sortable: false,
        },
        {
          value: 'measure', text: 'Ед.измерения', type: 'defaultItem', sortable: false,
        },
        {
          value: 'createdBy', text: 'Автор', type: 'defaultItem', sortable: false,
        },
      ],
      commissionHistory: [],
      commissionDictionary: {
        Delivery: {
          text: 'Подтвердите изменение стоимости доставки',
        },
        Platform: {
          text: 'Подтвердите изменение стоимости ставки',
        },
        Bonus: {
          text: 'Подтвердите изменение бонуса',
        },
      },
    };
  },
  watch: {
    commissionType: {
      handler(val) {
        if (val) this.getCommissionHistory();
      },
    },
  },
  computed: {
    fullInfo() {
      if (this.commission.value) {
        return this.commission.value.concat(` ${this.commission.currency?.nameRu || '%'}`);
      }
      return '';
    },
  },
  methods: {
    clear() {
      if (this.commission.commissionKind === 1) {
        this.commission.currency = null;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.openConfirm = true;
      } else {
        setTimeout(() => {
          this.$refs.form.resetValidation();
        }, 5000);
      }
    },
    saveEdit() {
      const { value, commissionKind, currency } = this.commission;
      this.$billing.post('commission', {
        typeId: this.commissionType.id,
        kindId: commissionKind,
        value: +value,
        currency: currency?.code || '',
      }).then(() => {
        this.openConfirm = false;
        this.$refs.form.reset();
        this.$emit('save');
      });

      // this.$api.post('/api/v1/crm/billing/commissions', {
      //   commissionMeasureType,
      //   commissionType,
      //   commissionValue,
      // }).then(() => {
      //   this.openConfirm = false;
      //   this.$emit('save');
      // });
    },
    getCommissionHistory() {
      this.$billing.get(`commission?typeid=${this.commissionType.id}&sorting=created,desc`).then((res) => {
        this.commissionHistory = res.data.map((item) => ({
          ...item,
          createdDate: this.$moment(item.created).format('DD-MM-YYYY HH:mm'),
          measure: item.currency ? item.currency : '%',
        }));
      });
    },
    clearForm() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.commission-edit-modal {
  &__actions {
    &.v-card__actions {
      padding: 12px 34px 20px!important;
    }

    &--save {
      background-color: #007450;
      border-radius: 18px;

      .v-btn__content {
        color: #FFFFFF;
        padding: 7px 18px;
      }
    }

    &--cancel {
      color: #007450;
      border-radius: 18px;
    }
  }
}
</style>
